
import Vue from 'vue'
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import es from "date-fns/locale/es";
import cons from "@/const";
import axios from "axios";

import DeviceDetector from "device-detector-js";

const regMail =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(window.navigator.userAgent);

export const getTipoDispositivoEsMobile = (isMobile) => {
    return device.device.type == 'smartphone' || device.device.type == 'tablet'
};

/**Inicio Fechas */
export const formatearFecha = (fecha) => {
    if (fecha == null) {
        return "-";
    }
    return format(new Date(fecha), "d/LLL/yyyy", {locale: es});
};

export const formatFechaDocumentoNormal = (fechaDoc) => {
    if (fechaDoc != null) {
        return format(new Date(fechaDoc), cons.formatoFechaDocumentos, {locale: es})
    } else {
        return "-";
    }
    
};

export const formatFechaEnDistancia = (fechaDoc) => {
    if(fechaDoc != null){
        return formatDistance(new Date(fechaDoc), new Date(), { addSuffix: true, locale: es})//.isSame(moment().clone().startOf('day'), 'd')
    }
     else{
        return "Nunca"
    }
};

export const formatFechaDocumento = (fechaDoc) => {
    //return format(new Date(fechaDoc), 'EEE d LLL/yy H:mm:ss', {locale: es})//'dd/MM/yyyy' 'EEE d LLL/yy H:mm:ss'
    if(fechaDoc != null){
        return formatDistance(new Date(fechaDoc), new Date(), { addSuffix: true, locale: es})//.isSame(moment().clone().startOf('day'), 'd')
    }
     else{
        return "Nunca"
    }
};

export const formatFechaNormal = (fecha) => {
    //return format(new Date(fechaDoc), 'EEE d LLL/yy H:mm:ss', {locale: es})//'dd/MM/yyyy' 'EEE d LLL/yy H:mm:ss'
    if (fecha != null) {
        return format(new Date(fecha), "EEE d LLL/yyyy H:mm", {locale: es})
    } else {
        return "-";
    }
};
/**Fin Fechas */

/** Inicio Ruts */
export const formatearRut = (rut) => {
    var rutaFormatear = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
    let len = rutaFormatear.length - 1;
    if (len > 0) {
        let dv = rutaFormatear[len];
        let beforeDv = rutaFormatear.substring(0, len).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        rutaFormatear = beforeDv + '-' + dv;
    }
    return rutaFormatear;
};

export const validarRut = (rutReg) => {
    var rutCompleto = rutReg.replace(/\./g, '');//replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
    
    if(rutCompleto == "" || rutCompleto == null){
        return false
    } else{

        rutCompleto = rutCompleto.replace(/-/g,"-");

        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
            return false;
        }

        var tmp = rutCompleto.split('-');
        var digv = tmp[1]; 
        var rut = tmp[0];

        if ( digv == 'K' ) digv = 'k' ;

        var M=0,S=1,T=rut;
        for(;T;T=Math.floor(T/10))
            S=(S+T%10*(9-M++%6))%11;
        return ((S?S-1:'k') == digv );
    }
};
/**Fin Ruts */

/** Valida que todos los parámetros booleanos proporcionados sean `true`.
 * 
 * @param {Array} opcionesValidacion - Un array que contiene dos valores: 
 *    el primero será retornado si todos los parámetros booleanos son `true`, 
 *    el segundo será retornado si alguno de ellos es `false`.
 * @param {...boolean} parametros - Una cantidad indefinida de parámetros booleanos que se validarán.
 * @returns {*} - Retorna el primer valor de `opcionesValidacion` si todos los parámetros son `true`, 
 *    de lo contrario retorna el segundo valor.
 *
 * */
export const validarEspacioColumna = (opcionesValidacion, ...parametros) => {
    return parametros.every(param => param === true) ? opcionesValidacion[0] : opcionesValidacion[1]
};

export const validarMail = (mail) => {
    if(!regMail.test(mail) || mail == ' ' || mail == null){
        return false
    } else {
        return true
    }


};

export const validarNegativos =  (valor, usarSigno=false) => {
    let tipoFiltro = usarSigno?'currency':'soloNumeros'
    
    if(parseInt(valor) > 0){
        return Vue.filter(tipoFiltro)(valor)
    }
    else if(parseInt(valor) == 0){
        return '0'
    } else{
        return `(${(Vue.filter(tipoFiltro)(valor * -1))})`
    }
};

export const borrarEspaciosTexto = (texto) => {
    return texto.replace(/\s+/g, '');
};

export const getColorEstadoDocumento = (documento) => {
    switch(documento.estadoDocumento) {
        case 1: return "info"
        case 2: return "danger"
        case 3: return "success"
        default: return "secondary"
    }
};

export const getEstadoDocumento = (documento) => {
    if(documento.hayDocumento){
        switch(documento.estadoDocumento) {
            case 1: return "En Revisión"
            case 2: return "Rechazado"
            case 3: return "Aceptado"
            default: return "Sin Enviar"
        }
    } else{
        return "Sin Documento"
    }
};

export const calcularLuminancia = (colorHex) => {
    // Convertir el color de hexadecimal a RGB
    let r = parseInt(colorHex.slice(1, 3), 16);
    let g = parseInt(colorHex.slice(3, 5), 16);
    let b = parseInt(colorHex.slice(5, 7), 16);
    let a = parseInt(colorHex.slice(7, 9), 16) / 255; // Transparencia

    // Aplicar opacidad a los valores RGB
    r = r * a + (1 - a) * 255; // Mezclado con un fondo blanco virtual (255, 255, 255)
    g = g * a + (1 - a) * 255;
    b = b * a + (1 - a) * 255;

    r /= 255;
    g /= 255;
    b /= 255;

    // Aplicar la corrección gamma
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    // Calcular la luminancia
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const validarTelefono = (numTelefono) => {

    let numAFormatear = numTelefono.replace(/[^0-9]/g, "");

    let error = "";
    if (numAFormatear === "") {
        error = "Ingrese solo numeros";
    }

    return { numAFormatear, error };

};
