<template>
  <div>
    <Preloader v-if="loading" />
    <router-view></router-view>
  </div>
</template>

<script>
    
    import cons from '@/const'
    import axios from "axios"
    import Preloader from './views/pages/componentes/Preloader.vue'
    export default {
        name: 'App',
        components: {
            Preloader
        },
        data() {
            return {
                loading: true,
                urlBase: cons.port+"://" + cons.ipServer,
                tokenLogin: (this.$cookie.get('userLogginToken')) != null ? JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario: null,
                intervalInfoID: null
            };
        },
        mounted() {
            /*if(this.intervalInfoID == null){
                this.verInformacionCliente()
                var idInt = setInterval(() => {
                    
                    this.verInformacionCliente();
                    
                    
                }, (6000 * 5));

                this.intervalNotificacionID = idInt
            }*/

            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
        methods: {
            verInformacionCliente: function(){
                var url = this.urlBase + '/get_info_cliente'
                axios({
                    method: "POST",
                    url: url,
                    "data": {
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    }).then((result) => {
                        
                    },
                    cancel =>{
                        

                    },
                    (error) => {
                        

                    }
                    )
                    .finally(() => {

                    });
            }
        }
    }
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
