const ambienteTest = false;

export default {
    appName: "Gesto",
    version: "1.0.0.8-Beta",
    //ipServer: "apireportes.vapp.cl",
    //ipServer: "138.255.100.250:8000",
    //ipServer: "192.168.103.98:8000",
    puertoQa: "",
    ipServer: (ambienteTest) ? "169.53.43.237:8000" : "apigesto.vapp.cl",//"169.53.43.237"+this.puertoQa,//"apireportes.vapp.cl:443",//"169.53.43.238:8000":"apireportes.vapp.cl",//"localhost:8000":"apireportes.vapp.cl",//"138.255.100.250:8000":"apireportes.vapp.cl",
    port: (ambienteTest) ? "http" : "https",
    //port: 'http',
    //intervalNotificacionID: null,
    rutaRedireccion: null,
    formatoFechaDocumentos: "EEE d LLL/yyyy",//"ddd D MMM/YYYY",//EEE d LLL/yyyy
    formatoDocumentosTrabajador: 'LLL yyyy',//"MMM/YYYY", //'LLL yyyy'
    formatoFechaRangoHonorarios: "yyyy-L-dd",//"D MMM YYYY",

    variablePrueba: {
        /* Espacio variable de prueba */
    },
    
    langPicker: {
        formatLocale: {
            firstDayOfWeek: 1,
            // MMMM
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            // MMM
            monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            // dddd
            weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
            // ddd
            weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            // dd
            weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        },
        monthBeforeYear: false,
    },
}
  //ipServer: "138.255.100.250:8000",
/*
agregar fecha de renovacion del cliente(no aqui, si no en la web)
*/